import { INITIAL_USER_STATE } from '../types';

import { actions } from './actions';
import { reducer } from './reducer';
import { selectors } from './selectors';

export const userState = {
    INITIAL_STATE: INITIAL_USER_STATE,
    actions,
    reducer,
    selectors,
};
